// These are to make babel happy
import "core-js/stable";
import "regenerator-runtime/runtime";

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/v4-shims.css'
import { t, initI18n } from 'ftc-common/i18n';
import pws from 'pws';

initI18n();

Object.assign(window, {
    t, pws
})
